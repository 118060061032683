var exports = {};

/**
 * Collects all values from an (async) iterable into an array and returns it.
 *
 * @template T
 * @param {AsyncIterable<T>|Iterable<T>} source
 */
const all = async source => {
  const arr = [];

  for await (const entry of source) {
    arr.push(entry);
  }

  return arr;
};

exports = all;
export default exports;